import { COLOURS } from '@core-ui/styles';
import { TableCell, TableRow } from '@core-ui/table';
import { buildURL } from '@core-ui/url';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DATE_FORMAT } from '@/app/consts/app';
import { ROUTES } from '@/app/consts/routes';
import { AmountFormatter, CurrencyCodeGetterById } from '@/app/types/common';
import { formatTime } from '@/app/utils/dates';
import IconButtonWithTooltip from '@/components/IconButtonWithTooltip';
import { ContractOutputSchema } from '@/generated';
import history from '@/history';
import { BACKOFFICE_TABS } from '@/pages/backoffice/consts';
import ContractsListRowMoreButton from '@/pages/backoffice/Contracts/ContractsList/ContractsListRowMoreButton';
import { Warning } from '@phosphor-icons/react';
import React from 'react';
import { FormattedMessage } from 'components/Intl';

interface IProps {
  item: ContractOutputSchema;
  amountFormatter: AmountFormatter;
  getCurrencyCode: CurrencyCodeGetterById;
}

const nameCellStyles: SxProps<Theme> = {
  cursor: 'pointer',
};

const ContractsListRow = (props: IProps) => {
  const { item, amountFormatter, getCurrencyCode } = props;

  const handleOpenContract = () => {
    history.push(
      buildURL(`/${item.boat_id}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.CONTRACTS}/${item.id}`, {
        tab: 'information',
      })
    );
  };

  const vendorName = item.vendor_name || <FormattedMessage id="label.no_info" />;

  const expirationDate = item.expires_at ? (
    <Typography color={item.is_expired ? COLOURS.Strawberry.Solid[600] : COLOURS.TEXT_CONTROL.MAIN}>
      {formatTime(item.expires_at, { dateFormat: DATE_FORMAT })}
    </Typography>
  ) : (
    <FormattedMessage id="label.no_info" />
  );

  const isContractExpired = item.is_expired && (
    <Tooltip title={<FormattedMessage id="label.contract_expired" />} placement="top" arrow>
      <SvgIcon htmlColor={COLOURS.Strawberry.Solid[600]}>
        <Warning />
      </SvgIcon>
    </Tooltip>
  );

  return (
    <TableRow>
      <TableCell sx={nameCellStyles} onClick={handleOpenContract}>{item.name}</TableCell>

      <TableCell>{vendorName}</TableCell>

      <TableCell>
        <Grid container justifyContent="space-between">
          <Grid item>{expirationDate}</Grid>
          <Grid item>{isContractExpired}</Grid>
        </Grid>
      </TableCell>

      <TableCell align="center">{item.transactions_number}</TableCell>

      <TableCell>{getCurrencyCode(item.currency_id)}</TableCell>

      <TableCell align="right">
        <Typography fontFamily="mono" fontWeight="semiBold">
          {amountFormatter(item.transactions_total_price)}
        </Typography>
      </TableCell>

      <TableCell padding="medium">
        <Grid container gap={2} justifyContent="flex-end" alignItems="center">
          <ContractsListRowMoreButton item={item} />

          <IconButtonWithTooltip onClick={handleOpenContract} tooltipLabel="label.open_contract" />
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default ContractsListRow;
