import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';

const SingleContractHeaderSkeleton = () => {
  return (
    <Grid container direction="column" gap={6} paddingY={6}>
      <Grid container direction="column" gap={2} marginBottom="38px">
        <Skeleton variant="rounded" height={26} width={144} />
        <Skeleton variant="rounded" height={26} width={260} />
        <Skeleton variant="rounded" height={26} width={200} />
      </Grid>

      <Skeleton variant="rounded" height={28} width={358} />
    </Grid>
  );
};

export default SingleContractHeaderSkeleton;
