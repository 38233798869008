import Loader from '@core-ui/loader';
import { TableCell, TableRow } from '@core-ui/table';
import Grid from '@mui/material/Grid';
import React from 'react';
import { FormattedMessage } from 'components/Intl';

interface IProps {
  item: string;
}

const SingleContractPlaceholderRow = (props: IProps) => {
  const { item } = props;

  return (
    <TableRow>
      {/* TODO: изменить colSpan на 8 когда добавим столбец чекбоксов */}
      <TableCell colSpan={7}>
        <Grid container justifyContent="flex-start" alignItems="center">
          <Grid item marginRight={2}>
            <Loader mode="circle" padding={0} size={15} />
          </Grid>

          <Grid item>
            {item}
            <FormattedMessage id="label.is_uploading" />
          </Grid>
        </Grid>
      </TableCell>

      <TableCell sticky right={252} shadow="dynamicLeft" />

      <TableCell sticky right={108} />

      <TableCell sticky right={0} />
    </TableRow>
  );
};

export default SingleContractPlaceholderRow;
