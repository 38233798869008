import { useQuery } from '@core-ui/redux-router';
import Tabs, { ITab } from '@core-ui/tabs';
import { buildURL } from '@core-ui/url';
import SvgIcon from '@mui/material/SvgIcon';
import { ROUTES } from '@/app/consts/routes';
import { useAppSelector } from '@/hooks/useAppSelector';
import { BACKOFFICE_TABS } from '@/pages/backoffice/consts';
import { SINGLE_CONTRACT_TAB_ENUM } from '@/pages/backoffice/Contracts/consts';
import { SingleContractQueryParams } from '@/pages/backoffice/Contracts/types';
import { openBackofficeModal } from '@/pages/backoffice/Modals/actions';
import { ImageCarouselModalData } from '@/pages/backoffice/Modals/command';
import { FileText, Image, Info } from '@phosphor-icons/react';
import React from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';
import selector from './selector';

const SingleContractTabs = () => {
  const { item, boatId } = useAppSelector(selector);
  const { tab } = useQuery<SingleContractQueryParams>();

  const dispatch = useDispatch();

  const handleOpenSourceFileModal = () => {
    if (item?.files) {
      const files = item.files.map((file) => file.link);

      dispatch(openBackofficeModal(new ImageCarouselModalData(files, item.id, undefined, undefined, item.created_at)));
    }
  };

  const tabs: ITab[] = [
    {
      value: SINGLE_CONTRACT_TAB_ENUM.INFORMATION,
      label: <FormattedMessage id="label.information" />,
      dataTestId: 'single-contract-info-tab',
      to: buildURL(`/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.CONTRACTS}/${item?.id}`, {
        tab: SINGLE_CONTRACT_TAB_ENUM.INFORMATION,
      }),
      icon: (
        <SvgIcon>
          <Info />
        </SvgIcon>
      ),
    },
    {
      value: SINGLE_CONTRACT_TAB_ENUM.TRANSACTIONS,
      label: <FormattedMessage id="label.transactions_capitalized" />,
      dataTestId: 'single-contract-transaction-tab',
      to: buildURL(`/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.CONTRACTS}/${item?.id}`, {
        tab: SINGLE_CONTRACT_TAB_ENUM.TRANSACTIONS,
      }),
      icon: (
        <SvgIcon>
          <FileText />
        </SvgIcon>
      ),
    },
    {
      value: SINGLE_CONTRACT_TAB_ENUM.SOURCE_FILE,
      label: <FormattedMessage id="label.source_file" />,
      dataTestId: 'single-contract-source-files-tab',
      onClick: handleOpenSourceFileModal,
      icon: (
        <SvgIcon>
          <Image />
        </SvgIcon>
      ),
      disabled: !(item?.files && item.files.length > 0),
    },
  ];

  return <Tabs size="small" tabs={tabs} active={tab} />;
};

export default SingleContractTabs;
