import { DATE_FORMAT } from '@/app/consts/app';
import { AmountFormatter } from '@/app/types/common';
import { formatTime } from '@/app/utils/dates';
import IconButtonWithTooltip from '@/components/IconButtonWithTooltip';
import StatusBadge from '@/components/StatusBadge';
import { FileStatusEnum, InboxRecordSchema } from '@/generated';
import { useAppSelector } from '@/hooks/useAppSelector';
import { CONTRACTS_PATHS } from '@/pages/backoffice/Contracts/routes';
import { toggleCheckedItem } from '@/pages/backoffice/Contracts/SingleContractTransactions/SingleContractTransactionsList/actions';
import { updateErpCategory } from '@/pages/backoffice/Contracts/SingleContractTransactions/SingleContractTransactionsList/SingleContractRow/actions';
import SingleContractRowMoreButton from '@/pages/backoffice/Contracts/SingleContractTransactions/SingleContractTransactionsList/SingleContractRowMoreButton';
import { SingleContractPathParams } from '@/pages/backoffice/Contracts/types';
import Autocomplete from '@core-ui/autocomplete';
import { useParams } from '@core-ui/redux-router';
import { COLOURS } from '@core-ui/styles';
import { TableCell, TableRow } from '@core-ui/table';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Check } from '@phosphor-icons/react';
import { FormattedMessage, useIntl } from 'components/Intl';
import { openBackofficeModal } from 'pages/backoffice/Modals/actions';
import { MasterDocumentModalData } from 'pages/backoffice/Modals/command';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import selector from './selector';
import useStyles from './styles';

interface IProps {
  item: InboxRecordSchema;
  amountFormatter: AmountFormatter;
}

const SingleContractRow = (props: IProps) => {
  const { item, amountFormatter } = props;

  const memoizedSelector = useMemo(selector, []);
  const { boatId, clientCostArticlesOptions, checked } = useAppSelector((state) =>
    memoizedSelector(state, {
      id: item.id,
    })
  );

  const params = useParams<SingleContractPathParams>(CONTRACTS_PATHS);
  const styles = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const isOpenDocumentActive = item.status === FileStatusEnum.DIGITIZED;

  const isSendToErpBulkDisabled =
    !item.erp_category || Boolean(item.is_sent_xero) || item.status !== FileStatusEnum.DIGITIZED;

  const isErpSelectorDisabled = item.status !== FileStatusEnum.DIGITIZED;

  const handleCheckboxChange = () => {
    if (!isSendToErpBulkDisabled) {
      dispatch(toggleCheckedItem(item.id));
    }
  };

  const handleOpenItem = () => {
    if (isOpenDocumentActive) {
      dispatch(openBackofficeModal(new MasterDocumentModalData({ documentId: item.document_id })));
    }
  };

  const handleErpChange = (value: number) => {
    if (item.document_id) {
      dispatch(
        updateErpCategory({
          documentId: item.document_id,
          categoryId: String(value),
        })
      );
    }
  };

  const createdAt = formatTime(item.created_at, { dateFormat: DATE_FORMAT });

  const uploadedByName = [item.uploaded_by_first_name, item.uploaded_by_last_name].filter(Boolean).join(' ') || (
    <FormattedMessage id="label.no_info" />
  );

  const reasonOfReturn =
    item.status === FileStatusEnum.RETURNED && item.reason_of_return
      ? `${item.status}: ${item.reason_of_return}`.replace(/_/g, ' ')
      : `${item.status}`.replace(/_/g, ' ');

  const paymentDate = item.paid_at ? (
    formatTime(item.paid_at, { dateFormat: DATE_FORMAT })
  ) : (
    <FormattedMessage id="label.no_info" />
  );

  return (
    <TableRow>
      {/* TODO: реализация чекбоксов для bulk actions отложена */}
      {/* <TableCell */}
      {/*   onClick={handleCheckboxChange} */}
      {/*   sx={{ */}
      {/*     ...styles.checkboxCell, */}
      {/*     cursor: isSendToErpBulkDisabled ? 'default' : 'pointer', */}
      {/*   }} */}
      {/* > */}
      {/*   <Checkbox disabled={isSendToErpBulkDisabled} checked={checked} size="large" /> */}
      {/* </TableCell> */}

      <TableCell>{item.filename}</TableCell>

      <TableCell>{item.vendor}</TableCell>

      <TableCell>{createdAt}</TableCell>

      <TableCell>
        <StatusBadge status={item.status} label={reasonOfReturn} />
      </TableCell>

      <TableCell padding="none" sx={styles.autocompleteCell}>
        <Autocomplete
          isDynamic
          hiddenBorder
          fullWidth
          fullHeight
          size="small"
          disabled={isErpSelectorDisabled}
          value={item.erp_category}
          onChange={handleErpChange}
          options={clientCostArticlesOptions}
          inputAriaLabel={intl.formatMessage({ id: 'aria.label.erp_category_selector' })}
        />
      </TableCell>

      <TableCell>{paymentDate}</TableCell>

      <TableCell>{uploadedByName}</TableCell>

      <TableCell sticky right={252} shadow="dynamicLeft">
        {item.is_sent_xero && (
          <Tooltip title={<FormattedMessage id="label.sent_to_erp" />} placement="top" arrow>
            <SvgIcon htmlColor={COLOURS.Avocado.Solid[600]}>
              <Check />
            </SvgIcon>
          </Tooltip>
        )}
      </TableCell>

      <TableCell sticky right={108} align="right">
        <Typography fontFamily="mono" fontWeight="semiBold">
          {amountFormatter(item.total)}
        </Typography>
      </TableCell>

      <TableCell padding="medium" sticky right={0}>
        <Grid container gap={2} justifyContent="flex-start" alignItems="center">
          <SingleContractRowMoreButton item={item} />

          {isOpenDocumentActive && (
            <IconButtonWithTooltip onClick={handleOpenItem} tooltipLabel="label.open_document" />
          )}
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default SingleContractRow;
