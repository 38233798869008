import ChunkLoader from '@/components/ChunkLoader';
import NoDataContainer from '@/components/NoDataContainer';
import NoDataPlaceholder from '@/components/NoDataPlaceholder';
import NoFiltratedDataPlaceholder from '@/components/NoFiltratedDataPlaceholder';
import TableSkeleton from '@/components/TableSkeleton';
import { useAppSelector } from '@/hooks/useAppSelector';
import { resetInboxFilters, updateInboxFiltersQuery } from '@/pages/backoffice/Inbox/InboxFilters/actions';
import { getChunkInboxList, getInboxList } from '@/pages/backoffice/Inbox/InboxList/actions';
import InboxPlaceholderRow from '@/pages/backoffice/Inbox/InboxList/InboxPlaceholderRow';
import InboxRow from '@/pages/backoffice/Inbox/InboxList/InboxRow';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import React from 'react';
import { batch, useDispatch } from 'react-redux';
import selector, { chunkLoaderSelector, getNoDataContainerData } from './selector';
import TableHeader from './TableHeaders';

const containerStyles: SxProps<Theme> = {
  paddingX: 6,
};

const InboxList = () => {
  const { placeholders, items, isFiltersApplied, amountFormatter, currencyCodeGetter } = useAppSelector(selector);
  const dispatch = useDispatch();

  const handleClearFilters = () => {
    batch(() => {
      dispatch(resetInboxFilters());
      dispatch(updateInboxFiltersQuery());
      dispatch(getInboxList(null));
    });
  };

  const noDataContent = isFiltersApplied ? (
    <NoFiltratedDataPlaceholder clearFilters={handleClearFilters} />
  ) : (
    <NoDataPlaceholder />
  );

  return (
    <NoDataContainer
      selector={getNoDataContainerData}
      loader={<TableSkeleton rows={10} />}
      noDataContent={noDataContent}
      sx={containerStyles}
    >
      <Grid item width="100%" height="100%">
        <Table stickyHeader>
          <TableHeader />

          <TableBody>
            {placeholders.map((placeholder) => (
              <InboxPlaceholderRow key={placeholder} item={placeholder} />
            ))}

            {items?.map((item) => (
              <InboxRow
                key={item.id}
                item={item}
                amountFormatter={amountFormatter}
                currencyCodeGetter={currencyCodeGetter}
              />
            ))}
          </TableBody>
        </Table>

        <ChunkLoader selector={chunkLoaderSelector} getChunk={getChunkInboxList} />
      </Grid>
    </NoDataContainer>
  );
};

export default InboxList;
